import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function PrivacyPolicyPage() {
  const { t, i18n } = useTranslation('privacyPolicy');

  return (
    <Container sx={{ mt: 6 }}>

      <Box component='section' sx={{ py: 4 }} dangerouslySetInnerHTML={{ __html: t('text') }}>

      </Box>

    </Container>
  );
}

export default PrivacyPolicyPage;
