import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import { Language } from "./language";
import {
  footerEn, footerUa,
  localizationEn,
  localizationUa,
  navigateMenuEn,
  navigateMenuUa, privacyPolicyEn, privacyPolicyUa,
  suggestionEn,
  suggestionUa,
  userAgreementEn,
  userAgreementUa
} from "./localization";


const resources = {
  [Language.En]: {
    transaction: localizationEn,
    navigateMenu: navigateMenuEn,
    suggestion: suggestionEn,
    footer: footerEn,
    privacyPolicy: privacyPolicyEn,
    userAgreement: userAgreementEn,
  },
  [Language.Ua]: {
    transaction: localizationUa,
    navigateMenu: navigateMenuUa,
    suggestion: suggestionUa,
    footer: footerUa,
    privacyPolicy: privacyPolicyUa,
    userAgreement: userAgreementUa
  }
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('lng'),
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
    },
  });

export default i18n;
